import { Injectable } from '@angular/core';
import { CacheProperty } from 'src/app/models/cache.model';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  public get(property: CacheProperty): string {
    return localStorage.getItem(property) || '';
  }

  public set(property: CacheProperty, value: string): boolean {
    localStorage.setItem(property, value);
    return true;
  }

  public remove(property: CacheProperty): boolean {
    localStorage.removeItem(property);
    return true;
  }
  
}
