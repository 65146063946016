<nav class="flex-row flex-center-y">
    <div class="logo">
        <h3 [routerLink]="['/home']">Regexpert</h3>
    </div>
    <div class="links flex-1">

    </div>

    <div class="user">
        <ng-container *ngIf="current_user.is_authenticated">

            <svg class="icon menu-icon" 
                 (click)="openAccountMenu()"
                 width="25" 
                 height="25" 
                 viewBox="0 0 24 24" 
                 stroke-width="1.5" 
                 stroke="#777777" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M4 6l16 0" />
                    <path d="M4 12l16 0" />
                    <path d="M4 18l16 0" />
            </svg>

            <ng-container *ngIf="show_account_menu">
                <div class="account-menu" [@anim]="show_account_menu">
                    <div class="current-user">
                        <p class="text-light text-small">Logged in as:</p>
                        <p>{{ current_user.email }}</p>
                        <p class="text-small">
                            {{ current_user.is_subscriber ? 'Premium Account' : 'Free Account'}}
                            <span *ngIf="current_user.early_adopter">
                                - 🎊 Early Adopter
                            </span>
                        </p>
                    </div>
                    <ul class="flex-col" (click)="closeAccountMenu()">
                        <li>
                            <a (click)="logout()">
                                Logout
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/account']">
                                Edit Account
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/account/bug']">
                                Report a Bug
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['#']">
                                Upgrade! (Coming soon)
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="account-menu-backdrop" (click)="closeAccountMenu()"></div>
            </ng-container>

        </ng-container>
        <ng-container *ngIf="!current_user.is_authenticated">
            <a [routerLink]="['/auth/login']">Login</a> | 
            <a [routerLink]="['/auth/signup']">Sign Up</a>
        </ng-container>
    </div>
</nav>
