import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AuthRequired } from '../api/api.service';
import { CacheService } from '../cache/cache.service';
import { IApiBaseResponse, IApiResponseWithData } from 'src/app/models/api.model';
import { AuthService } from '../auth/auth.service';
import { IEmailVerificationRequest, ILoginResponse, IPasswordResetRequest, ISignupLoginRequest } from 'src/app/models/auth.model';
import { CacheProperty } from 'src/app/models/cache.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private Api_Service: ApiService,
    // private Toaster: ToasterService,
    private Auth_Service: AuthService,
    private Cache_Service: CacheService,
    private Router: Router,
  ) { }

  public async signup(email: string, password: string): Promise<void> {
      let { success, message } = await this.Api_Service.call<IApiBaseResponse, ISignupLoginRequest>(
        AuthRequired.NOT_REQUIRED, 
        'post', 
        '/auth/signup', 
        { email, password }
      )
      if (!success) throw message;
  }

  public async verifyEmail(email: string, verification_code: string): Promise<void> {
    let { success, message } = await this.Api_Service.call<IApiBaseResponse, IEmailVerificationRequest>(
      AuthRequired.NOT_REQUIRED, 
      'post', 
      '/auth/verify', 
      { email, verification_code }
    )
    if (!success) throw message;
  }

  public async resendVerificationCode(email: string): Promise<void> {
    const { success, message } = await this.Api_Service.call<IApiBaseResponse, Pick<ISignupLoginRequest, 'email'>>(
      AuthRequired.NOT_REQUIRED,
      'post',
      '/auth/resend',
      { email }
    )
  if (!success) throw message;
  }

  public async forgotPassword(email: string): Promise<void> {
    let { success, message } = await this.Api_Service.call<IApiBaseResponse, Pick<ISignupLoginRequest, 'email'>>(
      AuthRequired.NOT_REQUIRED, 
      'post', 
      '/auth/forgot', 
      { email }
    )
    if (!success) throw message;
  }

  public async resetPassword(email: string, password: string, verification_code: string): Promise<void> {
    let { success, message } = await this.Api_Service.call<IApiBaseResponse, IPasswordResetRequest>(
      AuthRequired.NOT_REQUIRED, 
      'post', 
      '/auth/reset', 
      { email, password, verification_code }
    )
    if (!success) throw message;
  }

  public async login(email: string, password: string): Promise<void> {
    const { success, message, data } = await this.Api_Service.call<IApiResponseWithData<ILoginResponse>, ISignupLoginRequest>(AuthRequired.NOT_REQUIRED, 'post', '/auth/login', { email, password })
    if (!success) throw message
    // Retrieve ID token from response and save it to cache
    this.Cache_Service.set(CacheProperty.id_token, data.IdToken);
    this.Cache_Service.set(CacheProperty.refresh_token, data.RefreshToken);
    this.Cache_Service.set(CacheProperty.access_token, data.AccessToken);
    // Update auth status
    this.Auth_Service.refreshAuthStatus();
  }

  public async logout(redirect_url: string = '') {
    this.Cache_Service.remove(CacheProperty.id_token);
    this.Cache_Service.remove(CacheProperty.refresh_token);
    this.Cache_Service.remove(CacheProperty.access_token);
    this.Auth_Service.refreshAuthStatus();
    this.Router.navigate(['/'], { queryParams: { redirect: redirect_url } })
  }

  public async deleteAccount() {
    const access_token = this.Cache_Service.get(CacheProperty.access_token);

    const { success, message } = await this.Api_Service.call<IApiBaseResponse>(
      AuthRequired.REQUIRED, 
      'delete', 
      `/user?access_token=${access_token}`
    );
    if (!success) throw message;
    this.logout();
  }

  public async refreshToken(): Promise<void> {
    // Get refresh token from cache
    const refresh_token = this.Cache_Service.get(CacheProperty.refresh_token);
    if (!refresh_token) throw 'No refresh token found';
    // Call refresh endpoint
    const { success, message, data } = await this.Api_Service.call<IApiResponseWithData<ILoginResponse>>(
      AuthRequired.NOT_REQUIRED, 
      'get', 
      `/auth/refresh?refresh_token=${refresh_token}`
    );
    if (!success) throw message
    // Retrieve tokens from response and update cache
    this.Cache_Service.set(CacheProperty.id_token, data.IdToken);
    this.Cache_Service.set(CacheProperty.refresh_token, data.RefreshToken);
    this.Cache_Service.set(CacheProperty.access_token, data.AccessToken);
    // Update auth status
    this.Auth_Service.refreshAuthStatus();
  }

  /**
   * Retrieves the user's lesson progress from the server, caches it, then returns it
   * The format of the returned object is:
   * {
   *  "${lesson_class}-${lesson_name}": "solution"
   * }
   */
  public async getUserProgress(): Promise<Record<string, string>> {
    const { success, message, data } = await this.Api_Service.call<IApiResponseWithData<Record<string, string>>>(
      AuthRequired.REQUIRED, 
      'get', 
      `user/progress`
    );
  
    if (!success) {
      console.log(message, data)
      throw message;
    }

    this.Cache_Service.set(CacheProperty.lesson_progress, JSON.stringify(data));

    return data
  }

  /**
   * Gets a summary of the user's lesson progress from cache
   * The format of the returned object is:
   * {
   *  "${lesson_class}-${lesson_name}": "solution"
   * }
   */
  public getUserProgressCache(): Record<string, string> {
    const progress_snapshot_string = this.Cache_Service.get(CacheProperty.lesson_progress);
    const progress_snapshot = progress_snapshot_string ? JSON.parse(progress_snapshot_string) : {};
    return progress_snapshot;
  }

  public updateUserProgress(lesson_class: string, lesson_name: string, solution: string): void {
    const progress_snapshot = this.getUserProgressCache();
    const lesson_id = `${lesson_class}-${lesson_name}`;

    progress_snapshot[lesson_id] = solution;

    this.Cache_Service.set(CacheProperty.lesson_progress, JSON.stringify(progress_snapshot));
  }


}
