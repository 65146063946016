import { Injectable } from '@angular/core';

export interface RegexEvalResult {
  match_count: number;
  evaluated_text: string;
  error: ProcessingError;
}

export enum ProcessingError {
  NONE = "",
  INVALID_REGEX = "Invalid regex",
  EMPTY_REGEX = "Empty regex",
}

@Injectable({
  providedIn: 'root'
})
export class RegexEvalService {

  constructor() { }

  /**
   * Compares text against a regex and returns the number of matches and the original text with
   * the matches highlighted using the <span class="highlight"> tag.
   * @param text The text to evaluate against the regex.
   * @param regex The regex to evaluate against the text.
   * @param goal_list A list of strings that the regex should match.
   * @param avoid_list A list of strings that the regex should not match.
   * @returns An object containing the number of matches and the evaluated text.
   */
  public process(text: string, regex: string): RegexEvalResult {

    const result: RegexEvalResult = {
      match_count: 0,
      evaluated_text: "",
      error: ProcessingError.NONE,
    }
    
    // check that the regex is valid
    try   { new RegExp(regex) }
    catch { 
      result.error = ProcessingError.INVALID_REGEX;
      return result;
    }

    // Check if the regex is empty. If it is, just return the sample text.
    if (regex == "") {
      result.error = ProcessingError.EMPTY_REGEX;
      return result;
    }
    
    // Otherwise, highlight the matches.
    result.evaluated_text = text.replace(new RegExp(regex, "gm"), (match: string) => {
      result.match_count++;
      return `<span class="highlight">${match}</span>`;
    });

    return result;
  }

  public removeRegexMatches(text: string, regex: string): string {
    return text.replace(new RegExp(regex, "gm"), "");
  }

}
