import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { CacheService } from '../cache/cache.service';
import { CacheProperty } from 'src/app/models/cache.model';

export enum TrackableEvent {
  // Sales page
  LandingPageView = 'Landing Page View',
  // Auth
  Login = 'Login',
  Signup = 'Signup',
  // Lesson
  LessonViewed = 'Lesson Viewed',
  LessonComplete = 'Lesson Complete',
  LessonNotFound = 'Lesson Not Found',
  // Other
  Bug = 'Bug',
}

export interface TrackableEventDetail {
  lesson_class: string;
  lesson_name: string;
  email: string,
  page: string,
  detail: string,
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private user_id: string = '';

  constructor(
    private AuthService: AuthService,
  ) {
    mixpanel.init(environment.mixpanel.token, {debug: true});
  }

  /**
   * The identify method is only called after the user has logged in.
   * Track() events will be sent without an identified user, as in the case of
   * landing page views, in which case the user is identified by device ID automatically
   * by mixpanel.
   */
  public identify() {
    // If the user is already identified, don't identify them again
    if (this.user_id) return;

    this.user_id = this.AuthService.getAuthStatusSnapshot()?.email;
    mixpanel.identify(this.user_id);
  }

  /**
   * Sends an event to Mixpanel for tracking.
   * @param event The name of the event to track
   * @param properties Additional properties to send with the event
   */
  public track(event: TrackableEvent, properties?: Partial<TrackableEventDetail>) {
    mixpanel.track(event, properties);
  }


}
