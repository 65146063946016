<div class="wrapper wrapper-medium">

    <div class="header margin-bottom-40">
        <h1>Introduction to Regex Course</h1>
        <p class="text-left margin-top-20">These 35 lessons, broken up into 7 sections, cover all of the basic concepts of regular expressions. After completing the full course you'll be able to read and write regular expressions for almost any pracitical purpose.</p>
    </div>

    <ng-container *ngIf="loading">
        <regexpert-loading [message]="loading_message"
                           container_height="350px"
                           icon_size="50px">
        </regexpert-loading>
    </ng-container>

    <ng-container *ngIf="!loading">
        <ul class="lessons">
            <ng-container *ngFor="let lesson of lesson_summary">
                    <!-- If lesson is LOCKED -->
                    <ng-container *ngIf="lesson.locked">
                        <li class="lesson-cell locked">
                            <h4>{{ lesson.name }}</h4>
                            <p>{{ lesson.description }}</p>
                            <div class="lock">
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                     class="icon-lock" 
                                     width="20" 
                                     height="20" 
                                     viewBox="0 0 24 24" 
                                     stroke-width="1.5" 
                                     stroke="#777777" 
                                     fill="none" 
                                     stroke-linecap="round" 
                                     stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
                                        <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                                        <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                                </svg>
                            </div>
                        </li>
                    </ng-container>
    
                    <!-- If lesson is NOT LOCKED -->
                    <ng-container *ngIf="!lesson.locked">
                        <li class="lesson-cell" 
                           [routerLink]="['/lesson', lesson.url, getNextLessonName(lesson.url)]">
                            <h4>{{ lesson.name }}</h4>
                            <p>{{ lesson.description }}</p>
                            <!-- If the user has made progress on this lesson, show progress dots -->
                            <div class="progress" *ngIf="user_progress[lesson.url]">
                                
                                    <div class="progress-bar">
                                        <div class="progress-bar-fill" 
                                             [style.width.%]="getProgressPercentage(user_progress[lesson.url].length)">
                                        </div>
                                    </div>
                                
                            </div>
                    </li>
                    </ng-container>
                
            </ng-container>
        </ul>
    </ng-container>
    
</div>