import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { LessonService } from 'src/app/services/lesson/lesson.service';
import { UserService } from 'src/app/services/user/user.service';
import { LessonSummaryItem } from 'src/app/models/lesson.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loading: boolean = true;
  public loading_message: string = 'Loading...';

  public lesson_summary: LessonSummaryItem[] = [];
  public user_progress: Record<string, string[]> = {};

  constructor(
    private Toaster: ToasterService,
    private LessonService: LessonService,
    private UserService: UserService,
    private Analytics: AnalyticsService,
  ) { }

  private async getLessonSummary() {
    const { success, message, data } = await this.LessonService.getLessonList();
    if (!success) {
      this.Toaster.warning(message);
      return;
    }
  
    this.lesson_summary = data.lesson_list as unknown as LessonSummaryItem[]
  }

  /**
   * Look up the last lesson completed in a given class and return the next number
   * @param lesson_class The class to look up in the user_progress object
   */
  public getNextLessonName(lesson_class: string): string {
    // If the user has not completed any lessons in this class, return 1
    if (!this.user_progress[lesson_class]) return '1';
    const last_lesson = this.user_progress[lesson_class].length;
    // If the user has completed (all) 5 lessons in this class, return 5
    if (last_lesson == 5) return '5';
    // Otherwise, return the next lesson number
    const next_lesson = last_lesson + 1;
    return next_lesson.toString();
  }

  private async getUserProgress(): Promise<void> {

    try {
      const progress_data = await this.UserService.getUserProgress();
      // User progess records arrive as ${class}-${name}: solution
      // We need to split them into an object with the key as ${class}: [name, name, name]
      Object.keys(progress_data).forEach(key => {
        const [lesson_class, lesson_name] = key.split('-');

        if (!this.user_progress[lesson_class]) this.user_progress[lesson_class] = [];

        this.user_progress[lesson_class].push(lesson_name);
      });

      // Check which lessons to unlock based on user progress
      this.unlockLessons();

    } catch(error) {
      const error_message = typeof error == 'string' ? error : 'Error retrieving user progress';
      console.log(error);
      this.Toaster.warning(error_message);
    }
  }

  private unlockLessons(): void {
    // For each lesson in the lesson summary, check if the user has completed the previous lesson
    // which happens when (all) 5 lessons have been completed
    this.lesson_summary.forEach((lesson, index) => {
      // If this is the first lesson, it is unlocked by default
      if (index == 0) {
        lesson.locked = false;
        return;
      }
      // If the previous lesson has been completed, unlock this lesson
      const previous_lesson = this.lesson_summary[index - 1];
      // If the user has no progress in the class then skip
      if (!this.user_progress[previous_lesson.url]) return;
      // Otherwise, record the number of items completed in the previous lesson
      const lesson_items_completed = this.user_progress[previous_lesson.url].length;
      // If the user has completed all 5 items in the previous lesson, unlock this lesson
      if (lesson_items_completed >= 5) lesson.locked = false;
    });

  }

  // Values passed will be between 1 and 5, where 5 means 100% complete
  public getProgressPercentage(lessons_complete: number | undefined) {
    if (!lessons_complete) return 0;
    return (lessons_complete / 5) * 100;
  }

  async ngOnInit(): Promise<void> {
    // Get lesson summary and user progress
    this.loading = true;
    this.loading_message = 'Loading lessons...';
    await this.getLessonSummary();
    this.loading_message = 'Updating your progress...';
    await this.getUserProgress();
    this.loading = false;
    // Capture user identity for analytics
    this.Analytics.identify();
  }

}
