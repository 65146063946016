import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegexpertButtonComponent } from './components/regexpert-button/regexpert-button.component';
import { RegexpertLoadingComponent } from './components/regexpert-loading/regexpert-loading.component';



@NgModule({
  declarations: [
    RegexpertButtonComponent,
    RegexpertLoadingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RegexpertButtonComponent,
    RegexpertLoadingComponent,
  ]
})
export class SharedModule { }
