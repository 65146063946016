import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ICurrentUser, IJwtIdToken } from '../../models/auth.model';
import jwt_decode from "jwt-decode";
import { CacheService } from '../cache/cache.service';
import { CacheProperty } from '../../models/cache.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public current_user: BehaviorSubject<ICurrentUser> = new BehaviorSubject<ICurrentUser>(this.userFactory());

  constructor(
    private Cache_Service: CacheService,
    private Router: Router
  ) { }

  /**
   * This is primarily useful for the auth guard, which will redirect to the login page if the user is
   * registered but not authenticated (i.e. their token is expired)
   * and it will redirect to the info page if the user is not registered at all.
   * @returns 
   */
  public isRegisteredUser() {
    return !!this.Cache_Service.get(CacheProperty.id_token);
  }

  public getAuthStatusSnapshot(): ICurrentUser {
    return this.current_user.getValue();
  }

  /**
   * Auth status is determined by the presence and validity of a JWT
   * This function will update the auth status based on:
   * - Whether the token exists in local storage
   * - Whether the token is expired
   * - Whether the token is validly issued
   */
  public refreshAuthStatus(): void {
    let unauthenticated_user = this.userFactory();
    let id_token = this.Cache_Service.get(CacheProperty.id_token);
    // If no token exists in local storage, set auth status to unauthenticated
    if (!id_token) {
      this.current_user.next(unauthenticated_user);
      return;
    }
    // Otherwise decode the token...
    let decoded_token: IJwtIdToken = jwt_decode<IJwtIdToken>(id_token);
    // and make sure its valid
    if (this.tokenIsExpired(decoded_token)) {
      this.current_user.next(unauthenticated_user);
      return;
    }
    // If the token is valid, set the auth status to authenticated
    this.current_user.next(this.userFactory(decoded_token));
  }

  public async logout(redirect_url: string = '') {
    this.Cache_Service.remove(CacheProperty.id_token);
    this.refreshAuthStatus();
    this.Router.navigate(['/auth/login'], { queryParams: { redirect: redirect_url } })
  }

  private tokenIsExpired(decoded_token: IJwtIdToken): boolean {
    return !!decoded_token.exp && decoded_token.exp < (Date.now() / 1000);
  }

  /**
   * Creates a non-authenticated user object if no token is passed,
   * otherwise it creates an authenticated user object using token data 
   * @param decoded_token Decoded Cognito JWT
   * @returns User object
   */
   public userFactory(decoded_token?: IJwtIdToken): ICurrentUser {
    if (decoded_token) {
      return { 
        is_authenticated: true, 
        email: decoded_token.email, 
        expires: decoded_token.exp, 
        user_id: decoded_token.sub,
        is_subscriber: decoded_token['custom:is_subscriber'] === 'true',
        customer_id: decoded_token['custom:customer_id'] || '',
        early_adopter: decoded_token['custom:early_adopter'] === 'true',
      }
    }
    return { 
      is_authenticated: false, 
      email: '', 
      expires: -1, 
      user_id: '',
      is_subscriber: false,
      customer_id: '',
      early_adopter: false,
    };
  }

}
