<div class="loading flex-col flex-center flex-center-y"
     [ngStyle]="getContainerStyle()">
    <svg xmlns="http://www.w3.org/2000/svg"
             class="loading-icon"
             [ngStyle]="getIconStyle()"
             viewBox="0 0 24 24" 
             stroke-width="1.5" 
             fill="none" 
             stroke-linecap="round" 
             stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
    </svg>
    <br>
    <p class="loading-message">{{ message }}</p>
</div>
