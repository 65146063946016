<div class="wrapper wrapper-medium">

    <!-- <img class="pattern" src="../../../assets/regexpert-pattern.svg" alt="pattern"> -->

    <section class="banner text-left">
        
        <h1 class="header-large">
            The 
            <span class="text-color-green">interactive</span> 
            way to master 
            <br>
            <span class="text-color-green">/</span>
            Regex
            <span class="text-color-green">/</span>
            !
        </h1>
        <p class="paragraph-large">If you've ever read something like 
           <span class="outline">^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]$</span> 
           and thought "Who on Earth can <u>actually</u> understand this gibberish?" then you're in the right place.
        </p>
        <div class="margin-top-20">
            <a [routerLink]="['/auth/signup']">
                <button class="btn btn-primary">Sign Up (it's free)</button>
            </a>
        </div>
    </section>

    <section class="text-left">

        <h1 class="text-center header-large margin-bottom-20">🧠 Go from 0 to Expert</h1>

        <div class="flex-col">
            <div class="flex-1 info-page paragraph-large">
                <p>This course assumes no prior knowledge of regular expressions and takes you through every major concept needed to master the subject. Every course section builds on the last, so you get plenty of opportunities to reinforce the things you've learned as you go along.</p>
            </div>
            <div class="flex-1 text-center">
                <img src="../../../assets/landing-page/course-shot.png" alt="lessons-overview-screenshot">
            </div>
            
        </div>

    </section>

    <section class="text-left">

        <h1 class="text-center header-large margin-bottom-20">🛠️ Learn by Doing</h1>

        <div class="flex-col">
            <div class="flex-1 info-page paragraph-large">
                <p>Every lesson is interactive. Each time you learn a new concept, you'll need to put it into practice before moving on. The interactive editor shows you the text that matches your regex as you type and changes color to indicate that you've gotten it right.</p>
            </div>
            <div class="flex-1 text-center">
                <img src="../../../assets/landing-page/lesson-shot.png" alt="lessons-overview-screenshot">
            </div>
            
        </div>
        
    </section>

    <section class="features text-left">

        <h1 class="text-center header-large margin-bottom-40">🔫 Give it a Shot</h1>

        <div class="regex-input-container">
            <label class="text-light">The text below is highlighted using this regex</label>
            <input type="text" 
                    name="regex"
                    class="regex-input"
                    [(ngModel)]="features_regex" 
                    (keyup)="highlightRegexMatches($event)">

        </div>

        <div class="info-page paragraph-large">
            <p [innerHTML]="evaluated_features_text"></p>
        </div>

    </section>

    <section class="text-center">
        <p class="paragraph-large">...Or take the shortcut and click this button</p>
        <a [routerLink]="['/auth/signup']">
            <button class="btn btn-primary">Sign Up</button>
        </a>
    </section>

</div>
