import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'regexpert-button',
  templateUrl: './regexpert-button.component.html',
  styleUrls: ['./regexpert-button.component.scss']
})
export class RegexpertButtonComponent implements OnInit {

  @Input() text: string = '';
  @Input() type: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'secondary-danger' = 'primary';
  @Input() size: 'small' | 'wide' | 'regular' = 'regular';
  @Input() width: string = '';
  @Input() is_loading: boolean = false;
  @Input() is_disabled: boolean = false;

  constructor() { }

  public getButtonClass() {
    const button_class_list = []

    // Get button type
    button_class_list.push(`btn-${this.type}`);
    // Get button size
    button_class_list.push(`btn-${this.size}`);
    
    return button_class_list
  }

  public getButtonStyle() {
    if (!this.width) return {};
    
    return {
      width: this.width || 'inherit'
    };
  }

  // Ensures that the loading icon is in the center of the button
  public getIconStyle() {
    if (!this.width) return {};
    
    const left_value = Number(this.width.replace('px', ''));
    const icon_width = 10;
    return {
      left: ((left_value / 2) - (icon_width / 2)) + 'px' || 'inherit'
    };
  }

  ngOnInit(): void {
  }

}
