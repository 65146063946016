import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IToast } from '../../models/toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  public toaster_message_stream: BehaviorSubject<IToast | undefined> = new BehaviorSubject<IToast | undefined>(undefined);

  constructor() { }

  public info(message: string, ttl?: number) {
    this.addMessage({
      message: message,
      type: 'info'
    }, ttl)
  }

  public warning(message: string, ttl?: number) {
    this.addMessage({
      message: message,
      type: 'warning'
    }, ttl)
  }

  public success(message: string, ttl?: number) {
    this.addMessage({
      message: message,
      type: 'success'
    }, ttl)
  }

  private addMessage(message: Omit<IToast, 'id' | 'state'>, ttl?: number) {
    this.toaster_message_stream.next({...message, ttl: ttl, state: 'active', id: Date.now()})
  }

}
