import { trigger, transition, style, sequence, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService } from '../../services/toaster/toaster.service';
import { IToast } from '../../models/toast.model';

@Component({
  selector: 'toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('anim', [
       transition('* => void', [
         style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)'}),
         sequence([
           animate(".4s ease", style({ height: '*', opacity: '.2', transform: 'translateX(400px)', 'box-shadow': 'none'  })),
           animate(".4s ease", style({ height: '0', opacity: 0, transform: 'translateX(400px)', 'box-shadow': 'none'  }))
         ])
       ]),
       transition('void => active', [
         style({ height: '0', opacity: '0', transform: 'translateX(400px)', 'box-shadow': 'none' }),
         sequence([
           animate(".4s ease", style({ height: '*', opacity: '.2', transform: 'translateX(400px)', 'box-shadow': 'none'  })),
           animate(".4s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)'  }))
         ])
       ])
   ])
 ]
})
export class ToasterComponent implements OnInit {

  public SUBSCRIPTIONS: Subscription[] = []
  public toaster_messages: IToast[] = [];

  constructor(private Toaster: ToasterService) {
    this.SUBSCRIPTIONS.push(this.Toaster.toaster_message_stream.subscribe(message => {
      if (message) {
        this.addMessage(message);
        this.scheduleCleanup(message)
      }
    }))
  }

  private addMessage(message: IToast) {
    this.toaster_messages.push(message)
  }

  public removeMessage(index: number) {
    this.toaster_messages.splice(index, 1)
  }

  public scheduleCleanup(message: IToast) {
    const ttl = message.ttl || 4000;

    setTimeout(() => { 
      const message_index = this.toaster_messages.map(message => message.id).indexOf(message.id)
      if (message_index > -1) this.toaster_messages.splice(message_index, 1)
    }, ttl);
  }


  public getMessageType(message: IToast) {
    return [message.type]
  }

  ngOnInit(): void {
  }

}