import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'regexpert-loading',
  templateUrl: './regexpert-loading.component.html',
  styleUrls: ['./regexpert-loading.component.scss']
})
export class RegexpertLoadingComponent implements OnInit {

  @Input() message: string = 'Loading...';
  @Input() icon_size: string = '30px';
  @Input() container_height: string = '100px';

  constructor() { }

  public getContainerStyle() {
    if (!this.container_height) return {};
    
    return {
      height: this.container_height || 'inherit'
    };
  }

  public getIconStyle() {
    if (!this.icon_size) return {};
    
    return {
      width: this.icon_size || 'inherit',
      height: this.icon_size || 'inherit'
    };
  }

  ngOnInit(): void {
  }

}
