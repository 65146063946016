import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { InfoComponent } from './views/info/info.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },{
    path: 'lesson',
    loadChildren: () => import('./modules/lesson/lesson.module').then(m => m.LessonModule),
    canActivate: [AuthGuard]
  },{ 
    path: 'account', 
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard]
  },{ 
    path: 'home', 
    component: HomeComponent, 
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },{ 
    path: 'info', 
    component: InfoComponent, 
    pathMatch: 'full' 
  },{ path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
