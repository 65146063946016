import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService, TrackableEvent } from 'src/app/services/analytics/analytics.service';
import { ProcessingError, RegexEvalService } from 'src/app/services/regex-eval/regex-eval.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {


  public features_text = `Did you know that the regex above would select all the phone numbers in this block of text? Like (770) 555-1234 or 404-123-9909 but not something like 8898, which is not a phone number. If you didn't, GREAT. That's why you're here. If you want to get started now, write a regex that will select this entire paragraph and you'll be taken to the signup page automatically. If you need some help, all you need to know is that '.' means 'any character' and '*' means 'zero or more of the previous character'. So, if you want to select the entire paragraph, you might try '.*'. But, that's not the ONLY answer!`;
  public features_regex = '[(]?[0-9]{3}[)]?[- ]?[0-9]{3}[- ]?[0-9]{4}';
  public evaluated_features_text = '';

  constructor(
    private RegexEval: RegexEvalService,
    private Toaster: ToasterService,
    private Router: Router,
    private Analytics: AnalyticsService,
  ) { }

  public setRegexPreset(regex: string): void {
    this.features_regex = regex;
    this.highlightRegexMatches();
  }

  public highlightRegexMatches($event?: any): void {
    // Get the keycode of the key that was pressed
    const keycode = $event?.keyCode;
    const keys_to_ignore = [16, 17, 18, 20, 27, 37, 38, 39, 40, 91, 93];
    console.log(keycode)
    
    // If the key pressed was not a letter or number, ignore it.
    if (keycode && keys_to_ignore.includes(keycode)) return;

    const { evaluated_text, match_count, error } = this.RegexEval.process(this.features_text, this.features_regex);

    // If the regex is empty, just return the sample text.
    if (error === ProcessingError.EMPTY_REGEX) {
      this.evaluated_features_text = this.features_text;
      return;
    }

    // For all other errors, return early
    if (error) return;

    this.evaluated_features_text = evaluated_text;

    this.checkForSecretAnswer(match_count);
  }

  public checkForSecretAnswer(match_count: number): void {
    const remaining_text = this.RegexEval.removeRegexMatches(this.features_text, this.features_regex);
    // The secret answer is any regex that selects the entire paragraph. So if there is no remaining text,
    // and the match count is 1, the user has found the secret answer.
    if (remaining_text === '' && match_count <= 2) {
      this.Toaster.success('👌 Nice!');
      // redirect to the signup page
      this.Router.navigate(['/auth/signup']);
    }
    console.log(remaining_text, match_count)

  }

  ngOnInit(): void {
    this.highlightRegexMatches()
    this.Analytics.track(TrackableEvent.LandingPageView);
  }

}
