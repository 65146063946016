<section class="toaster">
    <ul class="flex-col flex-uneven-end">
        <ng-container >

            <li class="toast" [ngClass]="getMessageType(toast)" *ngFor="let toast of toaster_messages; index as i" [@anim]="toast.state">
                <span class="close-btn" (click)="removeMessage(i)">&times;</span>
                <div class="header">
                    <p>{{ toast.type }}</p>
                </div>
                <div class="body">
                    <p>{{ toast.message }}</p>
                </div>
            </li>
        </ng-container>
    </ul>
</section>
