import { Injectable } from '@angular/core';
import { ApiService, AuthRequired } from '../api/api.service';
import { IApiResponseWithData } from 'src/app/models/api.model';
import { Lesson, LessonSummary } from 'src/app/models/lesson.model';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  constructor(
    private API: ApiService,
    private User: UserService,
  ) { }

  public async getLessonList() {
    const result = await this.API.call<IApiResponseWithData<LessonSummary>>(
      AuthRequired.REQUIRED, 
      'get', 
      `module/list/list`
    );
    // The list is stored as stringified JSON so we need to parse it before returning
    if (result.success) result.data.lesson_list = JSON.parse(result.data.lesson_list);
    return result;
  }

  public async getLesson(lesson_class: string, lesson_name: string): Promise<IApiResponseWithData<Lesson>> {
    return await this.API.call<IApiResponseWithData<Lesson>>(
      AuthRequired.REQUIRED, 
      'get', 
      `module/${lesson_class}/${lesson_name}`
    );
  }

  public async submitSolution(lesson_class: string, lesson_name: string, solution: string): Promise<IApiResponseWithData<{ correct: boolean }>> {
    const response = await this.API.call<IApiResponseWithData<{ correct: boolean }>, { solution: string }>(
      AuthRequired.REQUIRED, 
      'post', 
      `solve/${lesson_class}/${lesson_name}`, 
      { solution }
    );

    if (response.data.correct) {
      // Update the user's progress
      this.User.updateUserProgress(lesson_class, lesson_name, solution);
    }

    return response;
    }

    


}

