import { trigger, transition, style, sequence, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ICurrentUser } from 'src/app/models/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('anim', [
       transition('* => void', [
         style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)'}),
         sequence([
           animate(".3s ease", style({ height: '*', opacity: '.2', transform: 'translateX(255px)', 'box-shadow': 'none'  })),
           animate(".3s ease", style({ height: '0', opacity: 0, transform: 'translateX(255px)', 'box-shadow': 'none'  }))
         ])
       ]),
       transition('void => true', [
         style({ height: '0', opacity: '0', transform: 'translateX(255px)', 'box-shadow': 'none' }),
         sequence([
           animate(".3s ease", style({ height: '*', opacity: '.2', transform: 'translateX(255px)', 'box-shadow': 'none'  })),
           animate(".3s ease", style({ height: '*', opacity: 1, transform: 'translateX(0px)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)'  }))
         ])
       ])
   ])
 ]
})
export class NavComponent implements OnInit {

  private SUBSCRIPTIONS: Subscription[] = [];
  public current_user!: ICurrentUser;
  public show_account_menu: boolean = false;

  constructor(
    private Router: Router,
    private Auth_Service: AuthService,
  ) {
    this.Router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event) => {
      // Every time there is a nav event, check the user's auth status to ensure
      // that the token has not expired
      this.Auth_Service.refreshAuthStatus();
    });
  }

  public openAccountMenu() {
    this.show_account_menu = true;
  }

  public closeAccountMenu() {
    this.show_account_menu = false;
  }

  public async logout() {
    await this.Auth_Service.logout();
  }

  private listenForAuthEvents() {
    this.SUBSCRIPTIONS.push(this.Auth_Service.current_user.subscribe((current_user) => {
      this.current_user = current_user;
      // There was a weird bug where the account menu would open
      // whenever the user logged in. This is a hacky fix.
      this.show_account_menu = false;
    }));
  }

  ngOnInit(): void {
    // Listen for changes to auth status
    this.listenForAuthEvents();
    // Then refresh auth status. This basically checks for
    // a valid ID token in localstorage and updates the 
    // current_user observable accordingly.
    this.Auth_Service.refreshAuthStatus();
  }

  ngOnDestroy(): void {
    this.SUBSCRIPTIONS.forEach(sub => sub.unsubscribe());
  }

}
