import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheProperty } from '../../models/cache.model';
import { environment } from '../../../environments/environment';
import { CacheService } from '../cache/cache.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ToasterService } from '../toaster/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';

export enum AuthRequired {
  REQUIRED,
  NOT_REQUIRED,
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private Cache_Service: CacheService,
    private Auth_Service: AuthService,
    private Router: Router,
  ) { }

  /**
   * Check auth status before making any API call
   * If the user's auth has expired or they are not logged in, redirect them to the login page
   * and send a toaster message saying that their session has expired
   * @returns A boolean indicating whether the user is authenticated
  */
  private preflightAuthCheck(): void {
    this.Auth_Service.refreshAuthStatus();
    // If the user is not authenticated
    if (!this.Auth_Service.getAuthStatusSnapshot().is_authenticated) {
      // If the user has a token in cache, their session has expired
      if (this.Cache_Service.get(CacheProperty.id_token)) {
        // Get the current route so that they can be redirected back to it after logging in
        const current_route = this.Router.url
        this.Auth_Service.logout(current_route);
        throw 'Your session has expired. Please log in again.';
      }
      // Otherwise, they weren't logged in in the first place and this is not
      // an issue of session expiration
      else {
        throw 'You must be logged in to do that.'
      }
    }
  }

  /**
   * A generic method to make a GET, POST, PUT, or DELETE request to any API endpoint
   * @param verb The HTTP verb to use: GET, POST, PUT, or DELETE
   * @param endpoint The API endpoint to call
   * @param body The body of the request (optional)
   * @returns A promise of the type K, as indicated by the generic type supplied when invoked
   */
  public call<T, K = null>(auth_required: AuthRequired, verb: 'get' | 'post' | 'put' | 'delete', endpoint: string, body?: K): Promise<T> {
    // Make sure the user is authenticated if auth is required for this call
    if (auth_required === AuthRequired.REQUIRED) {
      this.preflightAuthCheck();
    }

    const url: string = `${environment.api}${endpoint}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.Cache_Service.get(CacheProperty.id_token) || ''
      })
    };

    switch (verb) {
      case 'get':
        return firstValueFrom(this.httpClient.get<T>(url, httpOptions))
      case 'post':
        return firstValueFrom(this.httpClient.post<T>(url, body, httpOptions));
      case 'put':
        return firstValueFrom(this.httpClient.put<T>(url, body, httpOptions));
      case 'delete':
        return firstValueFrom(this.httpClient.delete<T>(url, httpOptions));
    }
  }
}
